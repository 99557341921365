.container {
  max-width: 400px;
  padding: 0 20px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .container {
    padding: 0 30px;
    max-width: 768px;
  }
}

@media (min-width: 1366px) {
  .container {
    padding: 0 50px;
    max-width: 1366px;
  }
}

@media (max-width: 450px) {
  .container {
    margin: unset;
  }
}
