.svg {
  height: 15px;
  width: 20px;
  margin-right: 15px;
  stroke: #19495c;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #62d4d4;
  font-size: 16px;
  line-height: 1.25;
  padding: 30px 25px;
}

.isLoggedIn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 16px;
  color: #62d4d4;
  line-height: 1.25;
  height: clamp(8vh, 9vh, 10vh);
  padding: 0 25px;
}

.loginSvg {
  height: 15px;
  width: 20px;
  margin-right: 15px;
  stroke: #62d4d4;
}

.navigation {
  display: none;
}

.mobileNavigation {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 25;
  background-color: #ffffff;
  background-attachment: fixed;
  touch-action: none;
}

.avatar {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  object-fit: cover;
}

.navLink {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #19495c;
  font-size: 14px;
  line-height: 1.25;
  padding: 0 25px;
  height: clamp(8vh, 9vh, 10vh);
  border-bottom: 1px solid #efefef;
}

.userLoader {
  margin-top: 5px;
}

@media (min-width: 768px) {
  .isLoggedIn {
    height: 7vh;
  }

  .navLink {
    height: 7vh;
  }
}

@media (min-width: 1366px) {
  .login {
    color: #ffffff;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
  }

  .isLoggedIn {
    color: #ffffff;
    padding: 10px;
    font-size: 14px;
    height: auto;
    line-height: 1.5;
  }

  .nav {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list {
    display: flex;
  }

  .navigation {
    display: block;
  }

  .mobileNavigation {
    display: none;
  }

  .svg {
    height: 15px;
    width: 20px;
    margin-right: 5px;
    stroke: #ffffff;
  }

  .navLink {
    color: #ffffff;
    padding: 10px 6.5px;
    border-bottom: none;
    height: auto;
    font-size: 13px;
    line-height: 1.5;
  }

  .loginSvg {
    stroke: #ffffff;
    margin-right: 5px;
  }
}

@media (min-width: 361px) and (max-width: 1366px) {
  .navLink {
    font-size: 16px;
  }
}
