.footerSocialSection {
  background-color: #19495c;
}

.socialList {
  display: flex;
  justify-content: center;
}

.socialItem {
  display: flex;
}

.socLink {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
  line-height: 2.08;
  padding: 13px 0;
  cursor: pointer;
  transition: color 250ms ease-in-out;
}

.socLink:hover,
.socLink:focus {
  color: #ff8614;
}

.socialItem:not(:last-child) {
  margin-right: 50px;
}

.footerContentSection {
  background-color: #205d76;
  padding: 30px 0;
}

.itemNav {
  display: none;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconLocationWrapper {
  display: flex;
  justify-content: center;
}

.item:not(:last-child) {
  margin-bottom: 30px;
}

.header {
  font-size: 16px;
  line-height: 1.562;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}

.link {
  display: block;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.86;
  text-align: center;
  cursor: pointer;
  transition: color 250ms ease-in-out;
}

.link:hover,
.link:focus {
  color: #ff8614;
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .iconWrapper {
    justify-content: start;
  }

  .iconLocationWrapper {
    justify-content: start;
  }

  .list {
    display: flex;
    justify-content: space-between;
  }

  .item {
    width: 344px;
  }

  .item:not(:last-child) {
    margin-bottom: 0px;
    margin-right: 30px;
  }

  .header {
    margin-bottom: 0;
    text-align: left;
  }

  .text {
    line-height: 1.78;
    text-align: left;
  }

  .link {
    margin: 0;
    text-align: left;
  }
}

@media (min-width: 1366px) {
  .footerContentSection {
    padding: 20px 0;
  }

  .itemNav {
    display: block;
    width: 402px;
    margin-right: 30px;
    padding-left: 10px;
    border-left: 1px solid #ffffff;
  }

  .item {
    padding-left: 10px;
    border-left: 1px solid #ffffff;
    width: 402px;
  }
}
