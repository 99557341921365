@import url("styles/fonts.css");

.mainContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lazyLoaderWrapper {
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  color: #ff8614;
  background-color: #ffffff;
}

.active > svg {
  stroke: #ff8614;
}

.editTrue,
.editFalse {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.editTrue {
  stroke: #ff8614;
}

.editFalse {
  stroke: #19495c;
}

.editTrue:hover,
.editFalse:hover {
  transform: scale(1.2);
}

.mobileNavAnimation-enter {
  opacity: 0;
  transform: scale(0.9);
}

.mobileNavAnimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.mobileNavAnimation-exit {
  opacity: 1;
}

.mobileNavAnimation-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.seeMoreAnimation-enter {
  transform: translateY(-100%);
}

.seeMoreAnimation-enter-active {
  transform: translateY(0);
  transition: transform 500ms;
}

.seeMoreAnimation-exit {
  transform: translateY(0);
}

.seeMoreAnimation-exit-active {
  transform: translateY(-100%);
  transition: transform 500ms;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.swiper-button-disabled {
  display: none;
}

.swiper-pagination-fraction {
  text-align: center;
  color: #ff8614;
  width: 50px !important;
  height: 17px;
  font-size: 14px;
  border-radius: 10px;
  left: 10px !important;
  top: 10px;
  background-color: #fff;
}

@media (min-width: 1366px) {
  .lazyLoaderWrapper {
    height: 50vw;
  }
}
