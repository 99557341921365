.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logoWrapper {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.logo {
  width: 23px;
  height: 40px;
}

.header {
  color: #ffffff;
  max-width: 200px;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.25;
  text-align: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: inherit;
  border: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.button svg {
  height: 100%;
  width: 100%;
  fill: #ffffff;
}

.localesContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -34px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (min-width: 768px) {
  .logoWrapper {
    margin: 0 auto;
    display: block;
    padding-left: 5%;
  }

  .logo {
    display: block;
    width: 40px;
    height: 57px;
    margin: 0 auto;
    margin-bottom: 7px;
  }

  .button {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 1366px) {
  .logoWrapper {
    padding-right: 6%;
  }

  .button {
    display: none;
  }

  .container {
    align-items: baseline;
  }

  .localesContainer {
    position: absolute;
    display: flex;
    justify-content: normal;
    bottom: 0;
    right: 0;
  }
}
