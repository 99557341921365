@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat/Montserrat-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat/Montserrat-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat/Montserrat-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat/Montserrat-Medium.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat/Montserrat-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "DejaVu Sans";
  src: url("assets/fonts/DejaVuSans/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("assets/fonts/DejaVuSans/DejaVuSans-Bold.ttf") format("truetype");
}
