.localesBtn {
  background-color: transparent;
  font-size: 14px;
  line-height: 1.215;
  border: none;
  font-weight: 300;
  color: #ffffff;
  padding: 5px 10px;
  cursor: pointer;
  transition: color 250ms ease-in-out;
  text-transform: uppercase;
}

.activeLang {
  font-weight: 500;
}

@media (min-width: 1366px) {
  .localesBtn {
    display: block;
  }
  .activeLang {
    display: block;
  }
}
